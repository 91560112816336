/*

.preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(3n+4) {
    margin-left: 10vw!important;
    margin-right: 10vw!important;
    margin-top: -12vw!important;
}

.preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(3n+5) {
    margin-top: -12vw!important;
}

.preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(3n+6) {
    margin-top: -12vw!important;
}

.preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(5n+7) {
    margin-top: -12vw!important;
}




@media (min-width: 1680px){
    .preview-container .portfolio-hexagon .portfolio-text h3 {
        font-size: 1.25em;
        margin-bottom: 25px;

    }
    .preview-container .portfolio-hexagon.flip-box-front-text .flip-box-description {
        font-size: 1.25em;
    }
    .preview-container .teamup .left-outer-corner {
        top: 115px;
        left: 93px;
        width: 22vw;
        height: 25vw!important;
    }
    .preview-container .teamup .left-down-corner {
        left: 292px;
        top: 277px;
        z-index: 25;
        width: 178px;
    }

}


@media (min-width: 992px) {
    .preview-container .portfolio-hexagon{
        height: 19.36vw !important;
    }

    .preview-container .portfolio-sorting >  .portfolio-link:nth-child(1n+5) {
        margin-top: 5px!important;
    }

    .preview-container .portfolio-sorting >  .portfolio-link:nth-child(3n+5) {
        margin-left: 5px!important;
        margin-right: 5px!important;
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting > .portfolio-link:nth-child(3n+5) {
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting >  .portfolio-link:nth-child(3n+6) {
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting > .portfolio-link:nth-child(7) {
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting >  .portfolio-link:nth-child(1n+5) {
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting >  .portfolio-link:nth-child(1n+10) {
        margin-top: -4.6vw!important;
    }
    .preview-container .portfolio-sorting > .portfolio-link:nth-child(9n+11) {
        margin-left: 8vw!important;
    }
    .preview-container .portfolio-sorting > .portfolio-link:nth-child(9n+8) {
        margin-right: 8vw!important;
    }
    .preview-container .teamhexa {
        width: 403px;
        height: 463px;
        max-width: 403px;
    }


}

@media (min-width: 992px) {
    .preview-container .portfolio-hexagon{
        height: 19.36vw !important;
    }
    .preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(3n+4) {
        margin-left: 5px!important;
        margin-right: 5px!important;
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(3n+5) {
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(3n+6) {
        margin-top: 5px!important;
    }
    .preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(1n+7) {
        margin-top: -4.6vw!important;
    }
    .preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(9n+7) {
        margin-left: 8vw!important;
    }
    .preview-container .portfolio-sorting > .portfolio-hexagon:nth-child(9n+10) {
        margin-right: 8vw!important;
    }
    .preview-container .customer-journey .m22_content_text {
        padding: 0;
        padding-left: 1.1rem!important;
        font-size: 70%;
    }

    .preview-container .customer-journey figure {
        right: 0px;
        top: -54px;
    }
    .preview-container .portfolio-hexagon .portfolio-text h3 {
        font-size: 0.85em;
        margin-bottom: 7px;
    }
    .preview-container .portfolio-hexagon.flip-box-front-text .flip-box-description {
        font-size: 0.75em;
    }


}*/
.full-width {
    position: relative;
    margin-left: -42.2vw!important;
    left: 50%!important;
    width: 85.8vw !important;
}
.preview-container .m22.container.fullsize {
    max-width: 6000px;
    width: 84.8vw!important;
    margin-left: -42.2vw!important;
    left: 50%;
    position: relative;
}

.MuiBox-root .changepaddingRSElement{
    padding-top: 40px !important;
}
/*.preview-container .portfolio-sorting > .portfolio-link:nth-child(1n+10) {
    margin-top: -6.6vw!important;
}*/
