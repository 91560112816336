/*
Base css with all general settings
 */
@import "css/base.css";

/*
Sidebar css with all style to sidebar
 */
@import "css/sidebar.css";

/*
Footer css with all style to footer
 */
@import "css/footer.css";

/*
Modal css with all style to modal
 */
@import "css/modal.css";

/*
React Page CSS with all style to ract-page
 */
@import "css/react-page.css";

/*
Scrollbar CSS with all style to scrollbar
 */
@import "css/scrollbar.css";

/*
Tooltip CSS with all style to tooltip
 */
@import "css/tooltip.css";

/*
Modul Masonry CSS with all style to masonry
 */
@import "css/module/masonry.css";

/*
Modul Hexagon CSS with all style to hexagon
 */
@import "css/module/hexagon.css";

.alert-box {
    z-index:999999;
}
.border-center {
    width: 100%;
    border: red 1px solid;
    position: relative;
}

.border-center:before { content: '';
    position: absolute;
    bottom: 50%;
    border-bottom: 2px green solid;
    width: 100%;
    z-index:0;
}

.box {
border-top: 1px solid black;
    height: 30px;
}
.box > svg {
    position: absolute;
    height: 20px;
    margin-top: -5px;
    margin-left: 5px;
    padding: 0 10px;
}


.box > h3 {
    margin: 0;
    padding: 0;
}
.box:after {
     content : "";
     height  : 4px;
     width   : 100%;
     display: block;
     margin-top: -23px;
     background: #fff;
     border-radius: 4px;
 }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: white;
    padding-left: 20px;
    border-radius: 4px;
    border: 1px solid black;
}

.pro-sidebar.collapsed .theme-header {
    width: 100%;
}


.mtminus25{
    margin-top:-50px !important
}

.bbunset {
    border: unset !important;
    box-shadow: unset !important;
    margin-bottom: unset !important;
}
.IconPlus{
    position: absolute;
    right: 5px;
    top: 5px;
    background: #3870F7 !important;
    border-radius: 19px !important;
    color:#317391 !important;
    border-color: #317391 !important;
    z-index:99;
    height: 30px !important;
    width: 30px !important;
    text-align: center !important;
    padding: 2px !important;
    margin-top: 4px !important;
    margin-right: 4px !important;
    cursor: pointer;
}

.MuiBox-root > .col > .row {
    display: none;
}

.MuiBox-root:hover > .col > .row {
    display: block;
}

.MuiBox-root > .toolbox {
    height: 40px;
    margin-top: -58px;
}

#elementadd{
}
main{
    padding-bottom: 0px !important;
}



.settingsContent #share .col:has(.input-group-text) {
    min-width: unset !important;
    max-width: unset !important;
}

.settingsContent #home .col:has(.input-group-text) {
    min-width: unset !important;
    max-width: unset !important;
}

.settingsContent #home #slug6,.settingsContent #home #slug7{
    max-height: 110px !important;
    min-height: 110px !important;
}

.settingsContent #home #description9,.settingsContent #home #description10{
    max-height: 230px !important;
    min-height: 230px !important;
}

.settingsContent #home #title7,.settingsContent #home #title8{
    max-height: 110px !important;
    min-height: 110px !important;
}

.settingsContent #seo #seo_title1{
    max-height: 90px !important;
    min-height: 90px !important;
}

.settingsContent #seo #seo_description2{
    max-height: 215px !important;
    min-height: 215px !important;
}

.settingsContent #share #share_title0{
    max-height: 90px !important;
    min-height: 90px !important;
}

.settingsContent #share #share_description0{
    max-height: 315px !important;
    min-height: 315px !important;
}
.dropZone {
    border: 2px dashed #bbb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 15px;
    color: #bbb;
}

.h95{
    height:95% !important;
}

.eventsCalender #image_title_image_{
    max-width: 791px !important;
    max-height: 276.84px  !important;
    min-width: 791px !important;
    min-height: 276.84px  !important;
    background: lightgray;
}

#MenuHolder .sun-editor .se-dialog .se-dialog-back, .sun-editor .se-dialog .se-dialog-inner{
    top: 61px !important;
}
#MenuHolder  .sun-editor .se-file-browser .se-file-browser-back, .sun-editor .se-file-browser .se-file-browser-inner{
    top: 61px !important;
    bottom:50px !important;
    height:67% !important;
}
