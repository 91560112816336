

.first{
    color:white !important;
}



.btn-primary{
    background-color: #015b91;
    border-color: #015b91;
    color:white;
}


.col .ga-item img, .ga-item img {
    object-fit: scale-down;
}

.colMax{
    max-width: 270px !important;
}




.moduleNav{
    display:none;
    margin-top: 0px;
    height: calc(100vh - 0px);
    position: fixed;
    left: 0;
    z-index: 1010;
    top: 0;
}







/*.sun-editor .se-toolbar{
    position: fixed !important;
    bottom: 65px !important;
    right: 80px;
    top: unset !important;
    z-index: 999999999999999999999;
}*/

.formControl{
    padding-bottom: 40px;
    background-color: white;
}
.settingsWebBuilderModul {
    position: fixed;
    z-index: 1020;
    width: 264px !important;
    left: 0px;
    top: 95px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow-y: scroll;
    height: 90.5%;
}



.ContentButton{
    position: fixed;
    z-index: 1022;
    width: 276px !important;
    left: 0px;
    top: 0;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
    height: 95px;
}
.ElementBorder{
    border:4px solid transparent
}

.settingsContent {
    position: fixed;
    z-index: 1020;
    width: 264px !important;
    left:0px;
    top: 95px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow-y: scroll;
    height: 90.5%;
}

.ContentButton button{
    margin: 0px !important;
    width: 100% !important
}
.ContentButton a[role="button"] {
    margin: 0px !important;
    width: 100% !important
}




#Teaser{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    transition: opacity .4s ease;
    opacity: 0;
    background: hsla(0,0%,100%,.95);
    text-align: center;
    color: rgba(0,0,0,.97);
    display: inline-block;
    padding: 12px 24px;
    margin: 0 auto;
    border-radius: 12px 12px 0 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: .15em;
    box-shadow: 0 -5px 5px rgb(0 0 0 / 22%);
    pointer-events: none;
}

.vmiddle{
    vertical-align: middle;
}

.bgunset {
    background: unset !important;
}
