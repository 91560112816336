.padmarcolor {
    background-Color:White;
    padding: 15px;
    margin-Top: -15px;
}
/**
 * overview.tsx Zeile 33 37 46 oben
 * overview.tsx Zeile 38 unten
 */

.padcolor{
    background-Color:white;
    padding:15px;
}