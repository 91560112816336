
@media all and (min-width: 480px) {


    .Login {
        padding: 30px;
        margin: 0 auto;
        padding-top: 5em;
        padding-bottom: 5em;
        max-width: 350px;
        margin-top: 60px;
        box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
       /* border-radius: 15px;*/
        background: rgb(19,94,126);
        background: linear-gradient(180deg, rgba(19,94,126,1) 0%, rgba(16,59,91,1) 35%, rgba(1,91,145,1) 100%);
    }

    .Login form .btn{
        width: 100%;
    }

    .Login form {
        /*background: #1d1d1d;*/
        margin: 0 auto;
        max-width: 320px;
        color:#1d1d1d
    }
    #sidebarCol{
        /*display:none !important;*/
    }

    body{
        background-image: url("https://cdn.wum-solution.de//WUM/2022/08/22/l9/Hintergrunde-Login-Maske.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .logintext{
        color:white;
        text-align: center;
    }

    .inputField{
        opacity: 1 !important;
        background-color: #6BC5DF !important;
        color: black !important;
        border-color: #6BC5DF !important;
        border-radius: 25px !important;
    }

    #login{
        opacity: 1 !important;
        background-color: white !important;
        color: rgba(19,94,126,1) !important;
        border-color: white !important;
        border-radius: 25px !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
    }

    .undertext {
        font-size: 11px;
        padding-top: 5px;
    }

    input::placeholder {
        color: black;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: black;
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: black;
        opacity: 1; /* Firefox */
    }
}

.viewpw{
    float: right;
    margin-top: -30px;
    margin-right: 10px;
}
