.pro-sidebar-content{
    background: linear-gradient(180deg,#135e7e,#103b5b 35%,#015b91);
}

.MenuItem{
    color:black !important;
}

.tooltips .material-icons {
    color: #015b91;
}

.pro-sidebar .pro-menu .pro-menu-item.active{
    background-color: #a2d9f7;
    border-color: #a2d9f7;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #a2d9f7;
}
.pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item:hover{
    color: #015b91;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: hidden;
}

.pro-sidebar-content{
    overflow-y: scroll;
}
