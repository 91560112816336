.pro-sidebar-layout{
    background: white;
    color: #3d5170;
    /*box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);*/
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: white;
}
.edit-section{
    margin-top: 5px;
    text-align: left;
}
#ElementMenu .pro-sidebar{
    width: 264px;
    min-width: 264px;
}
#ElementMenu{
    z-index: 2001;
    width: 264px;
    top: 95px;
}

#ElementMenu .css-1wkqxgx{
    margin: 6px;
}

#ElementMenu .pro-sidebar .pro-menu {
    padding-top: 0px;
    padding-bottom: 10px;
}
.edit-section{
    width: 100%;
}
.clr-grey{
    color: #9197A5;
}
.clr-grey:hover{
    color: white;

}
.mui-Icon-button{
       
    background-color: #EBEBEB;
    color: #9197A5 !important;
    
    border-radius: 5px !important;

}
.mui-Icon-button:hover{
       
    background-color: #4D72F7 !important;
    color: white !important;

}
.bg-grey{
    background-color: #EBEBEB;
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}
.pointer{
    cursor: pointer;
}
.full-width{
    width: 100%;
}
.sidebarHeader-elements
{
    padding: 15px;
    /* margin-top:60px; */
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    /* height: 85px; */
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.SidebarLogo{
    height: 60px;
    max-height: 60px;
    max-width: 120px;
}

.ta-center {
    text-align: center;
}

#sidebarCol {
    margin-top: 0px;
    height: calc(100vh - 0px);
}
