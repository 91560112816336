.colorpadtop{
    background-Color:white;
    padding-Top: 15px;
}
/**
 * Zeile 11 (Oben)
 * Zeile 13 (Unten)
 */

.borifloat {
    float: right;
    margin-Right:15px;
    margin-Bottom:15px;
    max-Width:250px;
}